<template>
    <nav class="navbar navbar-default">
        <ul class="nav navbar-nav navbar-right">
            <li>
                <button class="appAuth0Button" v-show="!user.isAuthenticated" @click="handleLogin()">Log In</button>
                <button class="appAuth0Button" v-show="user.isAuthenticated" @click="handleLogout()">Log out </button>
            </li>
        </ul>
        <ul class="nav navbar-nav navbar-right w-1/3" v-show="user.company == 'ONL'">
            <li>
                <span v-if="this.user.userCompany != 'ONL'" class="bg-red text-white p-2 mt-2 rounded">Current company: {{ this.user.userCompany }}</span>
            </li>
        </ul>
    </nav>
</template>

<script>
import Auth from '@/components/auth/Auth';
const auth = new Auth();

import { useSettings } from '@/store/user';

export default {
    name: 'app-nav',
    setup() {
        return {
            user: useSettings()
        }
    },
    methods: {
        handleLogin() {
            auth.login();
        },
        handleLogout() {
            auth.logout();
        },
        isLoggedIn() {
            return auth.isAuthenticated();
        },
    },
};
</script>

<style>
.navbar-right {
    margin-right: 0px !important
}

.log {
    margin: 5px 10px 0 0;
}
</style>
<template>
  <h1>Overview of all available fabrics for our mills:</h1>
  <div v-if="user.userCompany != 'ONL'" class="grid grid-cols-4 gap-2">
    <div
      class="p-4 m-4 rounded-md bg-white"
      :class="a.freeFabricStock < 0 ? 'bg-red text-white' : ''"
      v-for="a in user.detailedFabricProduction"
      :key="a.color"
    >
      <div class="font-semibold">{{ a.fabricOrderd }}</div>
      <div>
        Free fabric (with production):<br />{{ numbers(a.freeFabricWithProduction) }}
        <span v-if="a.freeFabricWithProduction < 0"
          ><i class="bx" :class="'bx-error' || 'bx-square-rounded'" /> Needs
          attention</span
        >
      </div>
      <div>
        Free fabric (in stock):<br />{{ numbers(a.freeFabric) }}
        <span v-if="a.freeFabric < 0"
          ><i class="bx" :class="'bx-error' || 'bx-square-rounded'" /> Needs
          attention</span
        >
      </div>
    </div>
  </div>

  <div v-if="user.userCompany == 'ONL'" class="grid grid-cols-4 gap-2">
    <div
      class="p-4 m-4 rounded-md bg-white"
      :class="a.freeFabricStock < 0 ? 'bg-red text-white' : ''"
      v-for="a in user.onlFabric"
      :key="a.color"
    >
      <div class="font-semibold">{{ a.fabricOrderd }}</div>
      <div>
        Free fabric (with production):<br />{{ numbers(a.freeFabricWithProduction) }}
        <span v-if="a.freeFabricWithProduction < 0"
          ><i class="bx" :class="'bx-error' || 'bx-square-rounded'" /> Needs
          attention</span
        >
      </div>
      <div>
        Free fabric (in stock):<br />{{ numbers(a.freeFabric) }}
        <span v-if="a.freeFabric < 0"
          ><i class="bx" :class="'bx-error' || 'bx-square-rounded'" /> Needs
          attention</span
        >
      </div>
    </div>
  </div>
  <div v-if="user.userCompany == 'ONL'">
    <div class="table w-full mt-4 bg-white rounded-md">
      <div class="table-header-group">
        <div class="table-row text-black font-semibold">
          <div class="table-cell text-left" @click="sortList('companyId')">
            Company &#8597;
          </div>
          <div class="table-cell text-left" @click="sortList('fabricOrderd')">
            Fabric &#8597;
          </div>
          <div class="table-cell text-left">Free Fabric (including in production)</div>
          <div class="table-cell text-left">Free Fabric (in stock)</div>
          <div class="table-cell text-left"></div>
        </div>
      </div>
      <div class="table-row-group">
        <div
          class="table-row"
          v-for="(item, index) in user.detailedFabric"
          :key="item.p_id"
          :item="item"
          :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
        >
          <div class="table-cell">{{ item.companyId }}</div>
          <div class="table-cell">{{ item.fabricOrderd }}</div>
          <div class="table-cell">{{ item.freeFabricWithProduction }}</div>
          <div class="table-cell">{{ item.freeFabric }}</div>
          <!-- <div class="table-cell">{{ numbers(item.stock) }}</div> -->
          <div class="table-cell text-xs">
            <!-- <button v-on:click="itemOverdue.statusBooking = !itemOverdue.statusBooking" class="appAuth0Button" :class="(itemOverdue.statusBooking == true) ? 'bg-red' : 'bg-green'">{{ itemOverdue.statusBooking == true ? 'Mark as in production' : 'Mark as in stock' }}</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="user.millBookingsOverdue != 0">
    <h2>Supplier allocations that needs attention (ready date is overdue):</h2>
    <div class="table w-full mt-4 bg-white rounded-md">
      <div class="table-header-group">
        <div class="table-row text-black font-semibold">
          <div class="table-cell text-left">Ready Date</div>
          <div class="table-cell text-left">Fabric</div>
          <div class="table-cell text-left">Amount</div>
          <div class="table-cell text-left">Status</div>
          <div class="table-cell text-left">Last update</div>
          <div class="table-cell text-left"></div>
        </div>
      </div>
      <div class="table-row-group">
        <div
          class="table-row"
          v-for="(itemOverdue, index) in user.millBookingsOverdue"
          :key="itemOverdue.p_id"
          :itemOverdue="itemOverdue"
          :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
        >
          <div class="table-cell">{{ itemOverdue.readyDate }}</div>
          <div class="table-cell">{{ itemOverdue.fabricOrderd }}</div>
          <div class="table-cell">{{ numbers(itemOverdue.stock) }}</div>
          <div class="table-cell">
            {{ itemOverdue.statusBooking == "false" ? "In production" : "Completed" }}
          </div>
          <div class="table-cell">{{ itemOverdue.lastUpdated }}</div>
          <div class="table-cell text-xs">
            <!-- <button v-on:click="itemOverdue.statusBooking = !itemOverdue.statusBooking" class="appAuth0Button" :class="(itemOverdue.statusBooking == true) ? 'bg-red' : 'bg-green'">{{ itemOverdue.statusBooking == true ? 'Mark as in production' : 'Mark as in stock' }}</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import { storeToRefs } from "pinia";
import mixins from "@/components/mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      filterValue: "",
      sortedData: [],
      sortedbyASC: true,
    };
  },
  setup() {
    const user = useSettings();
    user.getDataMills();
    user.getDataSuppliers();
    user.getDataFabrics(user.userCompany);
    user.getDataDetailedFabrics(user.userCompany);
    user.getDataOnlFabrics();
    user.getDataOnlUsers();
    user.getUsers();

    const {
      getUsers,
      getDataMills,
      getDataFabrics,
      getDataDetailedFabrics,
      getDataOnlFabrics,
      getDataOnlUsers,
    } = storeToRefs(user);

    return {
      user,
      getUsers,
      getDataMills,
      getDataFabrics,
      getDataDetailedFabrics,
      getDataOnlFabrics,
      getDataOnlUsers,
    };
  },
  methods: {
    sumUp(item) {
      return (
        Number(item.statusProductionFalse) +
        Number(item.statusProductionTrue) +
        Number(item.statusBookingFalse) +
        Number(item.statusBookingTrue)
      );
    },
    getDetails(item) {
      this.user.$patch({ editFabricOrderd: item });
      this.$router.push("/fabric-overview-mill");
    },
    sortList(sortBy) {
      if (this.sortedbyASC) {
        // this.sortedData.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        this.user.onlFabric.sort((x, y) => (x[sortBy] > y[sortBy] ? -1 : 1));
        this.sortedbyASC = false;
      } else {
        this.user.onlFabric.sort((x, y) => (x[sortBy] < y[sortBy] ? -1 : 1));
        this.sortedbyASC = true;
      }
    },
  },
};
</script>

<template>
  <h1>Edit fabric page</h1>

  <label for="fabricName">Fabric name:</label><br />
  <input
    type="text"
    id="fabricName"
    v-model="user.editFabric.name"
    class="p-2 appInput"
  /><br />
  <label for="notes">Notes:</label><br />
  <textarea
    class="p-2 w-2/6 h-48"
    v-model="user.editFabric.notes"
    placeholder="Comments..."
  ></textarea>
  <br />
  <button class="appRegularButton" @click="this.$router.push('/edit-company')">
    Cancel
  </button>
  <button class="appRegularButton" @click="save()">Save changes</button>
</template>

<script>
import { useSettings } from "@/store/user";

export default {
  setup() {
    const user = useSettings();

    return { user };
  },
  data() {
    return {};
  },
  methods: {
    async save() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.user.editFabric),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/updateFabric",
        requestOptions
      );
      // eslint-disable-next-line
      const data = await response.json();
      this.$router.push("/edit-company");
    },
  },
};
</script>

<template>
  <h1>{{ tableHeader }}</h1>
  <h3>{{ tableSubHeader }}</h3>

  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/new-booking-mill')"
  >
    Create new booking
  </button>
  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/old-booking-mill')"
  >
    View completed bookings
  </button>
  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/deleted-booking-mill')"
  >
    View deleted bookings
  </button>
  <xlsx-workbook>
    <xlsx-sheet
      v-for="sheet in sheets"
      :collection="sheet.all"
      :key="sheet.name"
      :sheet-name="sheet.name"
    />
    <xlsx-download filename="Export.xlsx">
      <button v-if="user.userCompany != 'ONL'" class="appRegularButton mt-4">
        Export to excel sheet
      </button>
    </xlsx-download>
  </xlsx-workbook>

  <div v-if="user.userCompany != 'ONL'">
    <label for="search" class="mt-4">Filter:</label>
    <input
      type="text"
      id="search"
      v-model="searchInput"
      @input="filter"
      class="p-2 ml-2 mt-2 appInput"
      placeholder="Search for fabric name..."
    />
  </div>

  <div class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">Ready Date</div>
        <div class="table-cell text-left">Fabric</div>
        <div class="table-cell text-left">Amount (meters)</div>
        <div class="table-cell text-left">Currency</div>
        <div class="table-cell text-left">Price (meter)</div>
        <div class="table-cell text-left">Status</div>
        <div class="table-cell text-left">Comment</div>
        <div class="table-cell text-left">Last update</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(item, index) in user.millBookings"
        :key="item"
        :item="item"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
        v-show="item.statusProduction == 'false'"
      >
        <div class="table-cell">{{ item.readyDate }}</div>
        <div class="table-cell">{{ item.fabricOrderd }}</div>
        <div class="table-cell">{{ numbers(item.stock) }}</div>
        <div class="table-cell">{{ item.currency }}</div>
        <div class="table-cell">{{ item.currencyAmount }}</div>
        <div class="table-cell">
          {{
            item.statusProduction == "false"
              ? item.readyDate < today
                ? "In stock"
                : "In production"
              : "Completed"
          }}
        </div>
        <!-- <div class="table-cell"><button v-on:click="item.statusBooking = !item.statusBooking" class="appAuth0Button text-xs" :class="(item.statusBooking == 'true')?'bg-red':'bg-green'">{{ item.statusBooking == 'true' ? 'Mark as in production' : 'Mark as in stock' }}</button></div> -->
        <div class="table-cell">{{ item.comment }}</div>
        <div class="table-cell">{{ item.lastUpdated }}</div>
        <div v-if="user.userCompany != 'ONL'" class="table-cell">
          <!-- <button
            v-if="user.company == 'ONL'"
            v-on:click="deleteDetails(item, index)"
            class="appAuth0Button text-xs bg-red ml-2"
          >
            Delete
          </button> -->
          <button
            v-on:click="openDetails(item)"
            class="appAuth0Button text-xs bg-gray ml-2"
          >
            Edit
          </button>

          <button
            v-on:click="reconfirm(item, index)"
            class="appAuth0Button text-xs bg-gray"
          >
            Reconfirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import mixins from "@/components/mixins";
import moment from "moment";

import {
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "../../../node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";

export default {
  name: "AllBookings",
  mixins: [mixins],
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  setup() {
    const user = useSettings();
    user.getDataMills();
    user.getDataSuppliers();

    return { user };
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      sheets: [
        {
          name: "Export",
          data: [],
          all: this.user.millBookings,
        },
      ],
      searchInput: "",
    };
  },
  props: ["tableHeader", "tableSubHeader"],
  methods: {
    filter: function () {
      if (this.searchInput == "") {
        this.user.getDataMills();
        return;
      } else {
        const res = this.user.millBookings.filter((item) => {
          return item.fabricOrderd.toLowerCase().includes(this.searchInput.toLowerCase());
        });

        this.user.millBookings = res;
        return res;
      }
    },
    toogleComplete: function (item) {
      item.statusBooking = !item.statusBooking;
    },
    openDetails: function (item) {
      this.user.$patch({ editItem: item });
      this.$router.push("/edit-booking-mill");
    },
    deleteDetails: function (item, index) {
      this.user.$patch({ editItem: item });
      this.user.millBookings.splice(index, 1);
      this.user.deleteBooking(item);
      alert("Order was deleted");
    },
    reconfirm: function (item, index) {
      const res = this.user.updateBooking(item, "mills");

      res.then((res) => {
        this.user.millBookings[index].lastUpdated = res.now;
        console.log(res.now);
        alert("Order was reconfirmed");
      });
    },
  },
};
</script>

<style>
.table-cell {
  @apply p-2 align-middle;
}
</style>

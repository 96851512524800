<template>
  <h1>{{ tableHeader }}</h1>
  <h3>{{ tableSubHeader }}</h3>

  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/new-booking-supplier')"
  >
    Create new allocation
  </button>

  <button
    v-if="user.userCompany != 'ONL'"
    class="appRegularButton mt-4"
    @click="$router.push('/new-booking-supplier')"
  >
    View deleted allocations
  </button>

  <xlsx-workbook>
    <xlsx-sheet
      v-for="sheet in sheets"
      :collection="sheet.all"
      :key="sheet.name"
      :sheet-name="sheet.name"
    />
    <xlsx-download filename="Export.xlsx">
      <button v-if="user.userCompany != 'ONL'" class="appRegularButton mt-4">
        Export to excel sheet
      </button>
    </xlsx-download>
  </xlsx-workbook>

  <div v-if="user.userCompany != 'ONL'">
    <label for="search" class="mt-4">Filter:</label>
    <input
      type="text"
      id="search"
      v-model="searchInput"
      @input="filter"
      class="p-2 ml-2 mt-2 appInput"
      placeholder="Search for fabric name..."
    />
  </div>

  <div class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">CCD Date</div>
        <div class="table-cell text-left">Fabric</div>
        <div class="table-cell text-left">Amount (meters)</div>
        <div class="table-cell text-left">Supplier Name</div>
        <div class="table-cell text-left">Status</div>
        <div class="table-cell text-left">LC</div>
        <div class="table-cell text-left">Comment</div>
        <div class="table-cell text-left">Last update</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(item, index) in user.supplierBookings"
        :key="item"
        :item="item"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">{{ item.readyDate }}</div>
        <div class="table-cell">{{ item.fabricOrderd }}</div>
        <div class="table-cell">{{ numbers(item.stock) }}</div>
        <div class="table-cell">{{ item.supplierName }}</div>
        <div class="table-cell">
          {{ item.statusBooking == "false" ? "Ordered" : "Completed" }}
        </div>
        <div class="table-cell">{{ item.lcStatus }}</div>
        <div class="table-cell">{{ item.comment }}</div>
        <div class="table-cell">{{ item.lastUpdated }}</div>
        <div v-if="user.userCompany != 'ONL'" class="table-cell">
          <button v-on:click="openDetails(item)" class="appAuth0Button text-xs bg-gray">
            Edit
          </button>

          <button
            v-on:click="reconfirm(item, index)"
            class="appAuth0Button text-xs bg-gray mr-2"
          >
            Reconfirm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import mixins from "@/components/mixins";

import {
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "../../../node_modules/vue3-xlsx/dist/vue3-xlsx.cjs.prod.js";

export default {
  name: "AllBookings",
  mixins: [mixins],
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  setup() {
    const user = useSettings();
    user.getDataMills();
    user.getDataSuppliers();

    return { user };
  },
  data() {
    return {
      sheets: [
        {
          name: "Supplier bookings",
          all: this.user.supplierBookings,
        },
      ],
      searchInput: "",
    };
  },
  props: ["tableHeader", "tableSubHeader"],
  methods: {
    filter: function () {
      if (this.searchInput == "") {
        this.user.getDataSuppliers();
        return;
      } else {
        const res = this.user.supplierBookings.filter((item) => {
          return item.fabricOrderd.toLowerCase().includes(this.searchInput.toLowerCase());
        });

        this.user.supplierBookings = res;
        return res;
      }
    },
    toogleYesNo: function (item) {
      item.lcStatus = !item.lcStatus;
    },
    toogleComplete: function (item) {
      item.statusBooking = !item.statusBooking;
    },
    openDetails: function (item) {
      this.user.$patch({ editItem: item });
      this.$router.push("/edit-booking-supplier");
    },
    reconfirm: function (item, index) {
      const res = this.user.updateBooking(item, "suppliers");

      res.then((res) => {
        this.user.supplierBookings[index].lastUpdated = res.now;
        console.log(res.now);
        alert("Order was reconfirmed");
      });
    },
  },
};
</script>

<style>
.table-cell {
  @apply p-2 align-middle;
}
</style>

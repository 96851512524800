<template>
  <h1 class="mb-2">VMI</h1>

  <label class="text-black">Filter:</label>
  <input
    v-model="search"
    type="text"
    placeholder="Filter.."
    class="text-black ml-2 p-2"
  />

  <div class="table w-full mt-4 bg-white rounded-md">
    <div class="table-header-group">
      <div class="table-row text-black font-semibold">
        <div class="table-cell text-left">STYLE NUMBER</div>
        <div class="table-cell text-left">STYLE NAME</div>
        <div class="table-cell text-left">BRAND</div>
        <div class="table-cell text-left">SUBBRAND</div>
        <div class="table-cell text-left">TOTAL STOCK QTY.</div>
        <div class="table-cell text-left"></div>
      </div>
    </div>
    <div class="table-row-group">
      <div
        class="table-row"
        v-for="(item, index) in transformedArray"
        :key="item"
        :item="item"
        :class="index % 2 == 0 ? 'bg-gray-table-even' : ''"
      >
        <div class="table-cell">{{ item.STYLE_NUMBER }}</div>
        <div class="table-cell">{{ item.STYLE_NAME }}</div>
        <div class="table-cell">{{ item.BRAND }}</div>
        <div class="table-cell">{{ item.SUBBRAND }}</div>
        <div
          class="table-cell"
          :class="item.TOTAL_STOCK_QTY > 0 ? 'bg-green text-white' : ''"
        >
          {{ item.TOTAL_STOCK_QTY }}
        </div>
        <div v-if="user.userCompany != 'ONL'" class="table-cell">
          <button v-on:click="openDetails(item)" class="appAuth0Button text-xs bg-gray">
            Edit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettings } from "@/store/user";
import mixins from "@/components/mixins";
import { storeToRefs } from "pinia";

export default {
  mixins: [mixins],
  components: {},
  setup() {
    const user = useSettings();

    user.getDataMills();
    user.getDataSuppliers();
    user.getVMI();
    user.totalStockQty();

    const { getVMI, totalStockQty } = storeToRefs(user);

    return { user, getVMI, totalStockQty };
  },
  methods: {
    openDetails: function (item) {
      this.user.$patch({ editItem: item });
      this.$router.push("/vmi-data");
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    filteredArray() {
      return this.user.VMI.filter((item) => {
        return (
          item.STYLE_NUMBER.toString()
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          item.STYLE_NAME.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          item.BRAND.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          item.SUBBRAND.toString().toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    transformedArray() {
      const result = [];
      let stockAmount = null;

      for (const item of this.filteredArray) {
        // Define the new key and value to add
        const TOTAL_STOCK_QTY_key = "TOTAL_STOCK_QTY";
        const TOTAL_STOCK_QTY_value = 0;

        // Add the new key and value
        item[TOTAL_STOCK_QTY_key] = TOTAL_STOCK_QTY_value;

        for (stockAmount of this.user.VMItotalStockQty) {
          // Check if the color variant has changed
          if (item.STYLE_NUMBER == stockAmount.STYLE_NUMBER) {
            item.TOTAL_STOCK_QTY = stockAmount.TOTAL_STOCK_QTY;
          }
        }
        // Push the current item
        result.push(item);
      }

      result.sort((a, b) => new Date(b.TOTAL_STOCK_QTY) - new Date(a.TOTAL_STOCK_QTY));

      return result;
    },
  },
};
</script>

<style>
.table-cell {
  @apply p-2 align-middle;
}
</style>

<!-- eslint-disable vue/no-parsing-error -->
<template>
  <h1>New allocation</h1>
  <div class="flex">
    <div class="w-2/6">Mandatory information</div>
    <div class="w-1/6 ml-8">Optional information</div>
  </div>
  <div class="flex">
    <div class="w-2/6">
      <div class="w-1/3">
        <h3 v-if="user.userCompany != 'ONL'">Fabric:</h3>
        <select
          class="p-2"
          v-if="user.userCompany != 'ONL'"
          v-model="fabricOrderd"
          placeholder=""
          required
        >
          <option v-for:="fabric in user.millAvailableFabric">{{ fabric.name }}</option>
        </select>
      </div>
      <div class="w-1/3">
        <h3>Fabric (meters):</h3>
        <input class="p-2" v-model="stock" placeholder="" required />
        <h3>Booking date:</h3>
        <input
          class="p-2"
          v-model="bookingDate"
          placeholder=""
          @change="validateDate(this.bookingDate)"
          required
        />
        <h3>CCD date:</h3>
        <input
          class="p-2"
          v-model="readyDate"
          placeholder=""
          @change="validateDate(this.readyDate)"
          required
        />
      </div>
    </div>
    <div class="w-1/4 bg-gray text-white rounded-md p-4 ml-8">
      <h3>Fabric Name + Code:</h3>
      <input class="p-2 text-black" v-model="fabricNameCode" placeholder="" />
      <h3>Color:</h3>
      <input class="p-2 text-black" v-model="color" placeholder="" />
      <h3>LC Date:</h3>
      <input class="p-2 text-black" v-model="lcStatus" placeholder="" />
      <h3>Shipment Date:</h3>
      <input class="p-2 text-black" v-model="shipmentDate" placeholder="" />
    </div>
  </div>
  <div class="flex">
    <div class="w-full">
      <h3>Remarks:</h3>
      <textarea class="p-2 w-2/6 h-48" v-model="comment" placeholder="Comment"></textarea
      ><br />

      <button class="appRegularButton mt-8 bg-gray" @click="$router.push('/supplier')">
        Cancel
      </button>
      <button class="appRegularButton mt-8" @click="addBooking()">Send</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useSettings } from "@/store/user";

export default {
  name: "NewBookingSupplier",
  setup() {
    const user = useSettings();
    user.getDataFabrics();
    return { user };
  },
  data() {
    return {
      orderType: "use",
      brand: "",
      styleNo: "",
      styleName: "",
      fabricNameCode: "",
      color: "",
      millName: "",
      supplierName: "",
      companyId: this.user.userCompany,
      fabricOrderd: "",
      bookingType: "meter",
      statusBooking: "false",
      statusProduction: "false",
      stock: "",
      cretedByEmail: this.user.userEmail,
      comment: "",
      recalcMeter: "1.00",
      recalcYards: "2.20",
      recalcPcs: "2.50",
      readyDate: moment(new Date()).format("YYYY-MM-DD"),
      createdDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      bookingDate: moment(new Date()).format("YYYY-MM-DD"),
      shipmentDate: moment(new Date()).format("YYYY-MM-DD"),
      lcStatus: false,
      lastUpdated: moment(new Date()).format("YYYY-MM-DD HH:mm"),
    };
  },
  methods: {
    validateDate(date) {
      var pattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

      if (pattern.test(date)) {
        return this.date;
      } else {
        alert("Please enter a valid date in the format YYYY-MM-DD");
      }
    },
    async addBooking() {
      if (this.fabricOrderd != "") {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            orderType: this.orderType,
            brand: this.brand,
            styleNo: this.styleNo,
            styleName: this.styleName,
            fabricNameCode: this.fabricNameCode,
            color: this.color,
            millName: this.millName,
            supplierName: this.supplierName,
            companyId: this.companyId,
            fabricOrderd: this.fabricOrderd,
            bookingType: this.bookingType,
            statusBooking: this.statusBooking,
            statusProduction: this.statusProduction,
            stock: this.stock,
            cretedByEmail: this.cretedByEmail,
            comment: this.comment,
            recalcMeter: this.recalcMeter,
            recalcYards: this.recalcYards,
            recalcPcs: this.recalcPcs,
            readyDate: this.readyDate,
            createdDate: this.createdDate,
            bookingDate: this.bookingDate,
            lcStatus: this.lcStatus,
            shipmentDate: this.shipmentDate,
            lastUpdated: this.lastUpdated,
          }),
        };
        const response = await fetch(
          process.env.VUE_APP_BACKEND + "/data_supplier",
          requestOptions
        );
        // eslint-disable-next-line
        const data = await response.json();
        this.$router.push("/supplier");
      } else {
        alert("please check that fabric is added and price needs to be above 0");
      }
    },
  },
};
</script>

<style>
select,
input {
  @apply p-4;
}
</style>

import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2 bg-yellow text-black font-bold text-center" }
const _hoisted_2 = { class: "flex min-h-screen" }
const _hoisted_3 = { class: "py-2 px-8 mobile:w-6/6 tablet:w-6/6 desktop:w-full bg-gray-light" }
const _hoisted_4 = { class: "py-4 h-16 border-b border-gray" }
const _hoisted_5 = { class: "h-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarMenuAkahon = _resolveComponent("SidebarMenuAkahon")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshApp && _ctx.refreshApp(...args)))
      }, " An update is available.. Update now by clicking this banner :) ")
    ], 512), [
      [_vShow, _ctx.updateExists]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SidebarMenuAkahon),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppHeader)
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_router_view)
        ], 512), [
          [_vShow, $setup.user.isAuthenticated]
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppFooter)
        ])
      ])
    ])
  ], 64))
}
<template>
  <h1>Edit details of fabric booking</h1>
  <div class="flex">
    <div class="w-2/6">Mandatory information</div>
    <div class="w-1/6 ml-8">Optional information</div>
  </div>
  <div class="flex">
    <div class="w-2/6">
      <div class="w-1/3">
        <h3>Fabric:</h3>
        <input class="p-2" v-model="fabricOrderd" placeholder="" readonly />
        <h3>Currency:</h3>
        <select v-model="currency" placeholder="" required class="p-2">
          <option v-for:="c in currencies" :value="c.value">{{ c.name }}</option>
        </select>
        <h3>Price pr. meter (eg. 1.9):</h3>
        <input
          class="p-2"
          @change="validatePrice()"
          v-model="currencyAmount"
          placeholder=""
          required
        />
      </div>
      <div class="w-1/3">
        <h3>Fabric (meters):</h3>
        <input class="p-2" v-model="stock" placeholder="" required />
        <h3>Booking date:</h3>
        <input
          class="p-2"
          v-model="bookingDate"
          placeholder=""
          @change="validateDate(this.bookingDate)"
        />
        <h3>Ready date:</h3>
        <input
          class="p-2"
          v-model="readyDate"
          placeholder=""
          @change="validateDate(this.readyDate)"
        />
        <h3>Status:</h3>
        <input type="checkbox" id="checkbox" v-model="user.editItem.statusProduction" />
        <label for="checkbox">{{ toogleText(user.editItem.statusProduction) }}</label>
      </div>
    </div>
    <div class="w-1/4 bg-gray text-white rounded-md p-4 ml-8">
      <h3>Fabric Name + Code:</h3>
      <input class="p-2 text-black" v-model="fabricNameCode" placeholder="" />
      <h3>Color:</h3>
      <input class="p-2 text-black" v-model="color" placeholder="" />
    </div>
    {{ errors }}
  </div>
  <div class="flex">
    <div class="w-full">
      <h3>Remarks:</h3>
      <textarea class="w-2/6 h-48" v-model="comment" placeholder="Comments..."></textarea
      ><br />

      <button class="appRegularButton bg-gray" @click="$router.push('/mill')">
        Cancel
      </button>
      <button class="appRegularButton bg-red" @click="deleteBooking()">Delete</button>
      <button class="appRegularButton" @click="addBooking()">Send update</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useSettings } from "@/store/user";

export default {
  name: "EditBookingMills",
  setup() {
    const user = useSettings();

    return { user };
  },
  data() {
    return {
      orderType: "restock",
      p_id: this.user.editItem.p_id,
      brand: this.user.editItem.brand,
      styleNo: this.user.editItem.styleNo,
      styleName: this.user.editItem.styleName,
      fabricNameCode: this.user.editItem.fabricNameCode,
      color: this.user.editItem.color,
      millName: this.user.editItem.millName,
      supplierName: this.user.editItem.supplierName,
      companyId: this.user.editItem.companyId,
      fabricOrderd: this.user.editItem.fabricOrderd,
      bookingType: this.user.editItem.bookingType,
      statusBooking: this.user.editItem.statusBooking,
      statusProduction: this.user.editItem.statusProduction,
      stock: this.user.editItem.stock,
      cretedByEmail: this.user.editItem.cretedByEmail,
      comment: this.user.editItem.comment,
      recalcMeter: "1.00",
      recalcYards: "2.20",
      recalcPcs: "2.50",
      readyDate: this.user.editItem.readyDate,
      createdDate: this.user.editItem.createdDate,
      bookingDate: this.user.editItem.bookingDate,
      lastUpdated: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      shipmentDate: this.user.editItem.shipmentDate,
      lcStatus: this.lcStatus,
      currency: this.user.editItem.currency,
      currencyAmount: this.user.editItem.currencyAmount,
      currencies: [
        {
          name: "USD",
          value: "USD",
        },
        {
          name: "EUR",
          value: "EUR",
        },
        {
          name: "CNY",
          value: "CNY",
        },
      ],
    };
  },
  computed: {
    status() {
      return this.user.editItem.statusProduction ? "true" : "false";
    },
  },
  methods: {
    toogleText() {
      if (this.user.editItem.statusProduction == false) {
        // this.user.editItem.statusProduction = "false";
        return " In production";
      } else {
        // this.user.editItem.statusProduction = "true";
        return " Completed";
      }
    },
    validatePrice() {
      if (this.currencyAmount > 0) {
        return this.currencyAmount;
      } else {
        alert("Please enter a valid price above 0");
      }
    },
    validateDate(date) {
      var pattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

      if (pattern.test(date)) {
        return this.date;
      } else {
        alert("Please enter a valid date in the format YYYY-MM-DD");
      }
    },
    async addBooking() {
      if (this.currencyAmount > 0 && this.fabricOrderd != "") {
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            p_id: this.p_id,
            orderType: this.orderType,
            brand: this.brand,
            styleNo: this.styleNo,
            styleName: this.styleName,
            fabricNameCode: this.fabricNameCode,
            color: this.color,
            millName: this.millName,
            supplierName: this.supplierName,
            companyId: this.companyId,
            fabricOrderd: this.fabricOrderd,
            bookingType: this.bookingType,
            statusBooking: this.user.editItem.statusBooking,
            statusProduction: this.user.editItem.statusProduction,
            stock: this.stock,
            cretedByEmail: this.cretedByEmail,
            comment: this.comment,
            recalcMeter: this.recalcMeter,
            recalcYards: this.recalcYards,
            recalcPcs: this.recalcPcs,
            readyDate: this.readyDate,
            createdDate: this.createdDate,
            bookingDate: this.bookingDate,
            lastUpdated: this.lastUpdated,
            lcStatus: this.lcStatus,
            shipmentDate: this.shipmentDate,
            currency: this.currency,
            currencyAmount: this.currencyAmount,
          }),
        };
        const response = await fetch(
          process.env.VUE_APP_BACKEND + "/data_mills",
          requestOptions
        );
        // eslint-disable-next-line
        const data = await response.json();
        this.$router.push("/mill");
      } else {
        alert("please check that fabric is added and price needs to be above 0");
      }
    },
    async deleteBooking() {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this.user.editItem),
      };
      const response = await fetch(
        process.env.VUE_APP_BACKEND + "/data_mills",
        requestOptions
      );
      // eslint-disable-next-line
      const data = await response.json();
      this.$router.push("/mill");
    },
  },
};
</script>

<style>
select,
input {
  @apply p-2;
}
</style>

<!-- eslint-disable vue/no-parsing-error -->
<template>
  <h1>New fabric booking</h1>
  <div class="flex">
    <div class="w-2/6">Mandatory information</div>
    <div class="w-1/6 ml-8">Optional information</div>
  </div>
  <div class="flex">
    <div class="w-2/6">
      <div class="w-1/3">
        <h3>Fabric:</h3>
        <select v-model="fabricOrderd" placeholder="" required class="p-2">
          <option v-for:="fabric in user.millAvailableFabric">{{ fabric.name }}</option>
        </select>
        <h3>Currency:</h3>
        <select v-model="currency" placeholder="" required class="p-2">
          <option v-for:="c in currencies" :value="c.value">{{ c.name }}</option>
        </select>
        <h3>Price pr. meter (eg. 1.9):</h3>
        <input
          class="p-2"
          @change="validatePrice()"
          v-model="currencyAmount"
          placeholder=""
          required
          min="0.1"
        />
      </div>
      <div class="w-1/3">
        <h3>Fabric (meters):</h3>
        <input class="p-2" v-model="stock" placeholder="" required />
        <h3>Booking date:</h3>
        <input
          class="p-2"
          v-model="bookingDate"
          placeholder=""
          @change="validateDate(this.bookingDate)"
          required
        />
        <h3>Ready date:</h3>
        <input
          class="p-2"
          v-model="readyDate"
          placeholder=""
          @change="validateDate(this.readyDate)"
          required
        />
        <div v-if="warning != ''" class="text-sm text-red">{{ warning }}</div>
      </div>
    </div>
    <div class="w-1/4 bg-gray text-white rounded-md p-4 ml-8">
      <h3>Fabric Name + Code:</h3>
      <input class="p-2 text-black" v-model="fabricNameCode" placeholder="" />
      <h3>Color:</h3>
      <input class="p-2 text-black" v-model="color" placeholder="" />
    </div>
  </div>
  <div class="flex">
    <div class="w-full">
      <h3>Remarks:</h3>
      <textarea
        class="p-2 w-2/6 h-48"
        v-model="comment"
        placeholder="Comments.."
      ></textarea
      ><br />

      <button class="appRegularButton mt-8 bg-gray" @click="$router.push('/mill')">
        Cancel
      </button>
      <button class="appRegularButton mt-8" @click="addBooking()">Send</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useSettings } from "@/store/user";

export default {
  name: "NewBookingMills",
  setup() {
    const user = useSettings();
    user.getDataFabrics();
    return { user };
  },
  data() {
    return {
      orderType: "restock",
      brand: "",
      styleNo: "",
      styleName: "",
      fabricNameCode: "",
      color: "",
      millName: "",
      supplierName: "",
      companyId: this.user.userCompany,
      fabricOrderd: "",
      bookingType: "meter",
      statusBooking: "false",
      statusProduction: "false",
      stock: "",
      cretedByEmail: this.user.userEmail,
      comment: "",
      recalcMeter: "1.00",
      recalcYards: "2.20",
      recalcPcs: "2.50",
      readyDate: moment(new Date()).format("YYYY-MM-DD"),
      createdDate: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      bookingDate: moment(new Date()).format("YYYY-MM-DD"),
      shipmentDate: "",
      lastUpdated: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      lcStatus: false,
      warning: "",
      currency: "",
      currencyAmount: "",
      currencies: [
        {
          name: "USD",
          value: "USD",
        },
        {
          name: "EUR",
          value: "EUR",
        },
        {
          name: "CNY",
          value: "CNY",
        },
      ],
    };
  },
  methods: {
    validatePrice() {
      if (this.currencyAmount > 0) {
        return this.currencyAmount;
      } else {
        alert("Please enter a valid price above 0");
      }
    },
    validateDate(date) {
      var pattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

      if (pattern.test(date)) {
        return this.date;
      } else {
        alert("Please enter a valid date in the format YYYY-MM-DD");
      }
    },
    async addBooking() {
      if (this.currencyAmount > 0 && this.fabricOrderd != "") {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            orderType: this.orderType,
            brand: this.brand,
            styleNo: this.styleNo,
            styleName: this.styleName,
            fabricNameCode: this.fabricNameCode,
            color: this.color,
            millName: this.millName,
            supplierName: this.supplierName,
            companyId: this.companyId,
            fabricOrderd: this.fabricOrderd,
            bookingType: this.bookingType,
            statusBooking: this.statusBooking,
            statusProduction: this.statusProduction,
            stock: this.stock,
            cretedByEmail: this.cretedByEmail,
            comment: this.comment,
            recalcMeter: this.recalcMeter,
            recalcYards: this.recalcYards,
            recalcPcs: this.recalcPcs,
            readyDate: this.readyDate,
            createdDate: this.createdDate,
            bookingDate: this.createdDate,
            lastUpdated: this.lastUpdated,
            lcStatus: this.lcStatus,
            shipmentDate: this.shipmentDate,
            currency: this.currency,
            currencyAmount: this.currencyAmount,
          }),
        };
        if (this.readyDate < moment(new Date()).format("YYYY-MM-DD")) {
          this.warning = "Ready date can't be in the past!";
        } else {
          this.warning = "";
          const response = await fetch(
            process.env.VUE_APP_BACKEND + "/data_mills",
            requestOptions
          );
          // eslint-disable-next-line
          const data = await response.json();
          this.$router.push("/mill");
        }
      } else {
        alert("please check that fabric is added and price needs to be above 0");
      }
    },
  },
};
</script>

<style>
select,
input {
  @apply p-4;
}
</style>
